<template>
  <v-dialog v-model="dialog" persistent max-width="800px">
    <v-card>
      <validation-observer ref="modalObserver" v-slot="{ handleSubmit }">
        <v-form
          class="newClass"
          @submit.prevent="
            handleSubmit(
              dataReceived.title === 'Add County Zip'
                ? addNewZipCounty
                : updateExistingZipCounty
            )
          "
          ref="modalForm"
        >
          <v-card-title class="card-title-dialog">
            <span class="text-h6">{{ dataReceived.title }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="ZIP"
                    :rules="{
                      required: true,
                      min: 5,
                      max: 5,
                    }"
                    mode="lazy"
                  >
                    <v-text-field
                      label="ZIP"
                      :readonly="dataReceived.title !== 'Add County Zip'"
                      v-model="county.zip"
                      class="form-control"
                      placeholder="ZIP"
                      required
                      outlined
                      :hide-details="!errors.length"
                      :error-messages="errors"
                      :disabled="loading"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Type"
                    mode="lazy"
                  >
                    <v-text-field
                      label="Type"
                      v-model="county.type"
                      class="form-control"
                      placeholder="Type"
                      required
                      outlined
                      :hide-details="!errors.length"
                      :error-messages="errors"
                      :disabled="loading"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Primary City"
                    mode="lazy"
                  >
                    <v-text-field
                      label="Primary City"
                      v-model="county.primary_city"
                      class="form-control"
                      placeholder="Primary City"
                      required
                      outlined
                      :hide-details="!errors.length"
                      :error-messages="errors"
                      :disabled="loading"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Acceptable Cities"
                    mode="lazy"
                  >
                    <v-text-field
                      label="Acceptable Cities"
                      v-model="county.acceptable_cities"
                      class="form-control"
                      placeholder="Acceptable Cities"
                      required
                      outlined
                      :hide-details="!errors.length"
                      :error-messages="errors"
                      :disabled="loading"
                    ></v-text-field>
                  </validation-provider>
                </v-col>

                <v-col cols="12" sm="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Unacceptable Cities"
                    mode="lazy"
                  >
                    <v-text-field
                      label="Unacceptable Cities"
                      v-model="county.unacceptable_cities"
                      class="form-control"
                      placeholder="Unacceptable CitiesIP"
                      required
                      outlined
                      :hide-details="!errors.length"
                      :error-messages="errors"
                      :disabled="loading"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="State"
                    mode="lazy"
                  >
                    <v-text-field
                      label="State"
                      v-model="county.state"
                      class="form-control"
                      placeholder="State"
                      required
                      outlined
                      :hide-details="!errors.length"
                      :error-messages="errors"
                      :disabled="loading"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="County"
                    rules="required"
                    mode="lazy"
                  >
                    <v-text-field
                      label="County"
                      v-model="county.county"
                      class="form-control"
                      placeholder="County"
                      required
                      outlined
                      :hide-details="!errors.length"
                      :error-messages="errors"
                      :disabled="loading"
                    ></v-text-field>
                  </validation-provider>
                </v-col>

                <v-col cols="12" sm="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Timezone"
                    mode="lazy"
                  >
                    <v-text-field
                      label="Timezone"
                      v-model="county.timezone"
                      class="form-control"
                      placeholder="Timezone"
                      required
                      outlined
                      :hide-details="!errors.length"
                      :error-messages="errors"
                      :disabled="loading"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Area Code"
                    mode="lazy"
                    :rules="{
                      regex: /^[0-9]\d*(\.\d+)?$/,
                    }"
                  >
                    <v-text-field
                      label="Area Code"
                      v-model.number="county.area_codes"
                      class="form-control"
                      placeholder="Area Code"
                      required
                      outlined
                      :hide-details="!errors.length"
                      :error-messages="errors"
                      :disabled="loading"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Country"
                    mode="lazy"
                  >
                    <v-text-field
                      label="Country"
                      v-model="county.country"
                      class="form-control"
                      placeholder="Country"
                      required
                      outlined
                      :hide-details="!errors.length"
                      :error-messages="errors"
                      :disabled="loading"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Latitude"
                    mode="lazy"
                    :rules="{
                      regex: /^[0-9]\d*(\.\d+)?$/,
                    }"
                  >
                    <v-text-field
                      label="Latitude"
                      v-model="county.lat"
                      class="form-control"
                      placeholder="Latitude"
                      required
                      outlined
                      :hide-details="!errors.length"
                      :error-messages="errors"
                      :disabled="loading"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Longitude"
                    mode="lazy"
                    :rules="{
                      regex: /^[0-9]\d*(\.\d+)?$/,
                    }"
                  >
                    <v-text-field
                      label="Longitude"
                      v-model="county.lng"
                      class="form-control"
                      placeholder="Longitude"
                      required
                      outlined
                      :hide-details="!errors.length"
                      :error-messages="errors"
                      :disabled="loading"
                    ></v-text-field>
                  </validation-provider>
                </v-col>

                <v-col cols="12">
                  <validation-provider name="Active Event" mode="lazy">
                    <v-checkbox
                      label="Active Zip"
                      hide-details
                      class="mt-n2"
                      v-model="county.isActive"
                    ></v-checkbox>
                  </validation-provider>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="$emit('disableDialog', false), resetForm()"
            >
              Close
            </v-btn>
            <v-btn
              v-if="dataReceived.title == 'Add County Zip'"
              color="add-btn"
              type="submit"
              text
              :disabled="loading"
              :loading="loading"
            >
              Add
            </v-btn>
            <v-btn
              v-else
              color="add-btn"
              type="submit"
              text
              :disabled="loading"
              :loading="loading"
            >
              Update
            </v-btn>
          </v-card-actions>
        </v-form>
      </validation-observer>
    </v-card>
    <!-- </template> -->
  </v-dialog>
</template>

<script>
import ZipToCityCountyService from "../../services/ZipToCityCountyService";
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  watch: {
    dataReceived: {
      handler: function () {
        if (this.dataReceived.title === "Update County Zip") {
          this.county = this.dataReceived;
          this.county.zip = String(this.dataReceived.zip);
        }
      },
      deep: true,
    },
  },
  async mounted() {},
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    dataReceived: {
      type: Object,
    },
  },
  data: () => ({
    county: {
      zip: "",
      type: "",
      primary_city: "",
      acceptable_cities: "",
      unacceptable_cities: "",
      state: "",
      county1: "",
      timezone: "",
      area_codes: 0,
      country: "",
      county: "",
      lat: 0,
      lng: 0,
      isActive: true,
    },
    roleName: "",
    loading: false,
  }),

  methods: {
    async addNewZipCounty() {
      try {
        this.loading = true;
        this.county = {
          ...this.county,
          lat: Number(this.county.lat),
          lng: Number(this.county.lng),
        };
        const response = await ZipToCityCountyService.addZipCounty(this.county);

        if (!response.data.errorMessage) {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: "ZIP/County added successfully",
              type: "success",
            },
            { root: true }
          );
          this.resetForm();

          this.$emit("disableDialog", false);
        } else {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.errorMessage,
              type: "error",
            },
            { root: true }
          );
        }
        this.$emit("reloadZipCounty");
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    async updateExistingZipCounty() {
      try {
        this.loading = true;
        this.county = {
          ...this.county,
          lat: Number(this.county.lat),
          lng: Number(this.county.lng),
        };
        const response = await ZipToCityCountyService.updateZipCounty(
          this.county
        );
        if (!response.data.errorMessage) {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: "ZIP/County updated successfully",
              type: "success",
            },
            { root: true }
          );
          this.resetForm();

          this.$emit("disableDialog", false);
        } else {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.errorMessage,
              type: "error",
            },
            { root: true }
          );
        }
        this.$emit("reloadZipCounty");
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    resetForm() {
      this.$refs.modalObserver.reset();
      this.county = {};
    },
  },
};
</script>
