<template>
  <master-layout>
    <div class="mb-3 text-right">
      <v-btn
        class="ml-3 mt-3 add-btn"
        dark
        @click="exportCountyZip()"
        :disabled="loading"
      >
        <v-icon>mdi-download</v-icon> Export County Zip
      </v-btn>
      <v-btn
        class="ml-3 mt-3 add-btn"
        dark
        @click="addOrUpdateZipCounty('add')"
      >
        <v-icon>mdi-plus</v-icon> Add County Zip
      </v-btn>
    </div>
    <v-card>
      <v-row class="ma-0">
        <v-col cols="12" sm="8">
          <v-card-title class="pa-0">
            County Zip List
            <!-- <v-spacer></v-spacer> -->
          </v-card-title>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search by County/Zip/Primary City"
            title="Search by County/Zip/Primary City"
            single-line
            hide-details
            max-width="400px"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        item-key="id"
        :headers="registerHeader"
        :items="registrationList"
        class="elevation-1 mytable"
        :loading="loading"
        :page="filters.Page"
        :items-per-page="filters.PageSize"
        @update:options="applyFilters($event)"
        :server-items-length="getTotalrecords"
        :disable-pagination="loading"
        :footer-props="{
          'items-per-page-options': [10, 20, 50, 100],
          disablePagination: loading,
          disableItemsPerPage: loading,
        }"
      >
        <template slot="no-data">Sorry, nothing to display here :( </template>
        <template v-slot:[`item.isActive`]="{ item }">
          <v-icon style="color: #f0ce6a" v-if="item.isActive"
            >mdi-check-bold</v-icon
          >
          <v-icon style="color: #f0ce6a" v-else>mdi-close-thick</v-icon>
        </template>
        <template v-slot:[`item.unacceptable_cities`]="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <div class="text-ellipsis" v-bind="attrs" v-on="on">
                {{ item.unacceptable_cities }}
              </div>
            </template>
            {{ item.unacceptable_cities }}
          </v-tooltip>
          <div></div>
          <!-- <template v-slot:activator="{ props }">
            <v-btn v-bind="props">{{ item.unacceptable_cities }}</v-btn>
          </template> -->
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                text
                :disabled="item.imgByte === null"
                v-bind="attrs"
                v-on="on"
                @click="addOrUpdateZipCounty('update', item)"
              >
                <v-icon color="primary">mdi-pencil</v-icon></v-btn
              >
            </template>
            Edit County Zip
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <ZipToCountyModal
      :dialog="dialog"
      :dataReceived="updateZipToCountyValue"
      @reloadZipCounty="getCountyList()"
      @disableDialog="dialog = $event"
    />
  </master-layout>
</template>
<script>
import ZipToCityCountyService from "../services/ZipToCityCountyService.js";
import ZipToCountyModal from "../components/Modals/ZipToCountyModal.vue";
export default {
  components: {
    ZipToCountyModal,
  },

  name: "ZIPtoCityCounty",
  watch: {
    search: {
      handler: function (newVal) {
        if (newVal.length > 2) this.fetchEntriesDebounced();
        else if (newVal === "") this.fetchEntriesDebounced();
      },
      deep: true,
    },
  },

  data: () => ({
    isDelete: false,
    timeout: null,
    getTotalrecords: 10,
    deletedId: {},
    name: "",
    search: "",
    ZipToCountyDetail: [],
    dialog: false,
    updateZipToCountyValue: {},
    loading: false,
    filters: {
      Page: 1,
      PageSize: 10,
      Searchbyzip: "",
      SearchByCountyOrPrimaryCity: "",
      SortBy: "Zip",
      IsDesc: true,
    },
    registerHeader: [
      { text: "Zip", value: "zip", width: 100 },
      { text: "Type", value: "type", sortable: false },
      { text: "Primary City", value: "primary_city", sortable: false },
      {
        text: "Acceptable Cities",
        value: "acceptable_cities",
        sortable: false,
      },
      {
        text: "Unacceptable cities",
        value: "unacceptable_cities",
        sortable: false,
        width: 400,
      },
      { text: "State", value: "state", sortable: false },
      { text: "County", value: "county", sortable: true },

      { text: "Timezone", value: "timezone", sortable: false },
      { text: "Area Code", value: "area_codes", sortable: false },
      { text: "Country", value: "country", sortable: true },
      { text: "Latitude", value: "lat", sortable: false },
      { text: "Longitude", value: "lng", sortable: false },
      { text: "Active", value: "isActive", sortable: false },
      { text: "Actions", value: "action", width: 150, sortable: false },
    ],
    registrationList: [],
  }),
  async mounted() {
    this.getCountyList();
  },
  methods: {
    async applyFilters(e) {
      // this.filters.page = e;
      this.filters.Page = e.page;
      this.filters.PageSize = e.itemsPerPage;
      this.filters.SortBy = e.sortBy[0];
      // this.filters.SearchByCountyOrPrimaryCity = "";
      // this.filters.Searchbyzip = "";
      this.filters.IsDesc = e.sortDesc[0];
      this.getCountyList();
    },
    async getCountyList() {
      let payload = {
        params: this.filters,
      };
      try {
        this.loading = true;
        const response = await ZipToCityCountyService.getCountyList(payload);
        if (response.data.data) {
          this.registrationList = [];
          this.registrationList = response.data.data.zipCountyList
            ? response.data?.data?.zipCountyList
            : [];
          this.ZipToCountyDetail = response.data.data.zipCountyList
            ? response.data?.data?.zipCountyList
            : [];
          this.getTotalrecords = response.data.data.count;
        } else {
          console.log();
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    addOrUpdateZipCounty(value, item) {
      if (value == "add") {
        this.dialog = true;
        this.updateZipToCountyValue = { title: "Add County Zip" };
      } else {
        this.dialog = true;
        this.updateZipToCountyValue = {
          ...item,
          title: "Update County Zip",
        };
      }
    },
    deleteZipCounty(value) {
      this.isDelete = true;
      this.deletedId = value;
    },

    async exportCountyZip() {
      try {
        this.loading = true;
        let filter = {
          SearchByCountyOrPrimaryCity: this.filters.SearchByCountyOrPrimaryCity,
          SearchByZip: this.filters.Searchbyzip,
        };

        let payload = {
          responseType: "blob",
        };

        const response = await ZipToCityCountyService.exportCountyZip(
          filter,
          payload
        );
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1;
        let yyyy = today.getFullYear();

        const fileName = "Export County Zip" + `${mm}-${dd}-${yyyy}`;
        this.byteToFileReader(response.data, fileName);
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    byteToFileReader(value, fileName) {
      var blob = new Blob([value], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    },
    getSearchValue(val) {
      if (val) {
        var letters = /^[0-9]+$/;

        setTimeout(async () => {
          if (val.match(letters)) {
            this.filters.SearchByCountyOrPrimaryCity = "";
            this.filters.Searchbyzip = await val;
          } else {
            this.filters.Searchbyzip = "";
            this.filters.SearchByCountyOrPrimaryCity = await val;
          }
          this.filters.Page = 1;
          await this.getCountyList();
        }, 1000);
      } else {
        this.filters.Searchbyzip = "";
        this.filters.SearchByCountyOrPrimaryCity = "";
        this.getCountyList();
      }
    },
    fetchEntriesDebounced() {
      // cancel pending call
      clearTimeout(this._timerId);

      // delay new call 500ms
      this._timerId = setTimeout(() => {
        this.getSearchValue(this.search);
      }, 1500);
    },
  },
};
</script>

<style scoped>
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
}
</style>
>
