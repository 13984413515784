import http from "./http-client";

class ActivityService {
  // getActivityDetail(payload) {
  //   return http.get("/Activity/Details", payload);
  // }
  updateZipCounty(payload) {
    return http.post("/ZipCountyManage/UpdateCounty", payload);
  }
  getCountyList(payload) {
    if (!payload) {
      return http.get(`/ZipCountyManage/CountyList`);
    } else {
      return http.get(`/ZipCountyManage/CountyList`, payload);
    }
  }
  addZipCounty(payload) {
    return http.post("/ZipCountyManage/AddCounty", payload);
  }
  removeZipCounty(payload) {
    return http.post(`/ZipCountyManage/DeleteCounty?zip=${payload}`);
  }
  exportCountyZip(filter, payload) {
    return http.get(
      `/ZipCountyManage/ExportReport?SearchByCountyOrPrimaryCity=${filter.SearchByCountyOrPrimaryCity}&SearchByZip=${filter.SearchByZip}`,
      payload
    );
  }
}

export default new ActivityService();
